import React from 'react';

import styles from './styles.scss';

interface Props {
  name: string;
  position?: string;
}

const TeamMember = ({ name, position }: Props) => (
  <div className={styles.teamMember}>
    <span className={styles.name}>{name}</span>
    {position && <span className={styles.position}>({position})</span>}
  </div>
);

export default TeamMember;

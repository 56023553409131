import React from 'react';
import { Helmet } from 'react-helmet';

// component
import Container from '../../components/container';
import Hero from '../../components/hero';
import TeamMember from '../../components/team-member';

// sub section
import FAQ from './faq';

import styles from './styles.scss';

const Home = () => (
  <>
    <Helmet>
      <title>Funeral Cover | Bon Sano</title>
    </Helmet>
    <Hero />
    <Container id="services" className={styles.services}>
      <h2>Our Services</h2>
      <h3>Funeral Cover</h3>
      <p>
        Bon Sano provides a primary product of family funeral cover. This product is underwritten by
        BrightRock Life Limited, a licensed financial services provider and insurer. It is our firm
        intention to continue along this path and in addition we plan to offer appropriate value
        added products to enhance our offering to our loyal client base.
      </p>
      <hr className={styles.separator} />
      <h4>Downloadable forms</h4>
      <span className={styles.info}>
        Please email all completed forms to
        <a href="mailto:dhusselmann@bonsano.co.za">dhusselmann@bonsano.co.za</a>
        ; or
        <br />
        fax to (021) 945 4397
      </span>
      <h5>New Applications</h5>
      <a className={styles.downloadLink} href="/downloads/NewApplicationFormBR.xls" download>
        New Application Form
      </a>
      <h5>Existing Policies</h5>
      <a className={styles.downloadLink} href="/downloads/ClaimForm.pdf" download>
        Funeral Claim Form
      </a>
      <a className={styles.downloadLink} href="/downloads/BeneficiaryForm.pdf" download>
        Request To Change Beneficiary Details Form
      </a>
      <a className={styles.downloadLink} href="/downloads/DebitOrderFormChanges.pdf" download>
        Request To Change Banking Details Form
      </a>
      <a className={styles.downloadLink} href="/downloads/MemberDetailsUpdate.pdf" download>
        Request To Change Policy Details Form
      </a>
    </Container>
    <Container className={styles.blueHeader} alternate>
      <h2>Meet The Team</h2>
    </Container>
    <Container className={styles.team}>
      <TeamMember name="Mr. MS Patel" position="Chair Person" />
      <TeamMember name="Mr. GPL Bester" position="Vice Chair Person" />
      <TeamMember name="Mr. SD Husselmann" position="CEO" />
      <TeamMember name="Mr. D Jones" />
    </Container>
    <Container id="faq" className={styles.blueHeader} alternate>
      <h2>Frequently Asked Questions</h2>
    </Container>
    <Container className={styles.faq}>
      <FAQ />
    </Container>
  </>
);

export default Home;

import React from 'react';

import Container from '../container';

import FamilyUrl from './family.jpg';
import WaveUrl from './wave.svg';

import styles from './styles.scss';

const Hero = () => (
  <div className={styles.hero} style={{ backgroundImage: `url(${FamilyUrl})` }}>
    <h1>It&apos;s about living</h1>
    <img src={WaveUrl} alt="wave" className={styles.wave} />
  </div>
);

export default Hero;

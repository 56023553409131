import React from 'react';

import FAQ from '../../components/faq';

const FAQs = () => (
  <>
    <FAQ
      question="When does my cover commence?"
      answer="Membership of the scheme commences on the first day of the month in which the first monthly premium deduction is made."
    />
    <FAQ
      question="Who is all covered on this policy?"
      answer="This scheme covers main members, spouse and children under the age of 21"
    />
    <FAQ
      question="What happens when children reach the age of 21?"
      answer="Children over 21 will only be covered if they are full time students. If not we offer the option where children can take out their own policies and become the main member of their own policy."
    />
    <FAQ question="Can any extended family be covered?" answer="Yes." />
    <FAQ
      question="What happens when the member leaves the employer?"
      answer="Should the member leave the service of the employer; he/she shall be given the option to continue with the scheme by means of payment through bank debit order."
    />
    <FAQ
      question="Is the Bon Sano policy a paid up policy?"
      answer="No, cover ceases with the death of the main member. Spouse continuation is available and this should be done in the first six months of the main members death."
    />
    <FAQ
      question="Can adopted children be covered?"
      answer="Only legally adopted children will be covered. Supporting documents of proof will be required to add such a child."
    />
    <FAQ
      question="Is there an age restriction?"
      answer="Yes, only main members under the age of 55 (fifty five) will be able to join the Bon Sano scheme."
    />
    <FAQ
      question="When canceling your Bon Sano policy, does any money get paid out?"
      answer="No, when you cancel your policy no money will be paid out and the member will lose all the premiums he/she has paid in over the years."
    />
    <FAQ
      question="Is there accident cover on this policy?"
      answer="Yes, but only for the main member and spouse."
    />
    <FAQ
      question="Where should members claim?"
      answer="To claim any of the benefits offered by the scheme, members should contact the Bon Sano offices at (021) 945 4363 for full particulars."
    />
  </>
);

export default FAQs;

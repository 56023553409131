import React from 'react';

import styles from './styles.scss';

interface Props {
  question: string;
  answer?: string;
  children?: React.ReactNode;
}

const FAQ = ({ question, answer, children }: Props) => (
  <div className={styles.container}>
    <span className={styles.question}>{question}</span>
    <p>{answer || children}</p>
  </div>
);

export default FAQ;
